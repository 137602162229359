import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Native Android",
      desc: "My coding journey began when I secured my first job as a Native Android Developer back in 2013. Since then, I've embarked on an exciting quest for knowledge, continuously seeking opportunities to expand my expertise.",
      image: "images/android.svg",
    },
    {
      name: "Flutter Apps",
      desc: `One pivotal moment in my career was my decision to embrace Flutter, a versatile framework, ${new Date().getFullYear() - 2018} years ago. This choice allowed me to diversify my skill set while still keeping my Android native development experience intact.`,
      image: "images/flutter.svg",
      // icon: "fa-solid fa-cloud",
    },
    {
      name: "Backend Engineering",
      desc: "I design, build and maintain the server-side components of web-enabled applications. I specialize in utilizing technologies like Node.js and Express.js to architect server logic, while also managing SQL and NoSQL databases for data handling and storage, including MongoDB, Firestore, MySQL, PostgreSQL, SQL Server. Embracing serverless architectures, I work to optimize scalability and resource efficiency. Additionally, I take charge of deployment and hosting responsibilities, especially for self-hosted solutions.",
      image: "images/cloud.svg",
    },
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        {service.icon ? (<i className={service.icon} />) : (
                          <img
                            src={service.image}
                            width="32"
                            height="32"
                            alt=""
                          />)}

                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
