import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    FLUTTER_MOBILE: "Flutter Mobile",
    FLUTTER_WEB: "Flutter Web",
    ANDROID: "Native Android",
    UNITY: "Unity",
  };

  const projectsData = [
    {
      title: "SpacMan",
      projectInfo: `SpacMan brings every known SPAC into the palm of your hand. Making sure you never miss a merger, and are the first to have all of the important information. SpacMan gives you the latest news, merger alerts, agreement updates, meeting events and more much regarding the SPACs that you follow, all in one place!

      Follow as many trading and pre-ipo SPACs as you would like to be the first with updates regarding the companies that you are interested in.`,
      technologies: "Flutter, Node.js, MongoDB, Google Play Subscribtion, AppStore Subscription",
      urls: {
        android: "https://play.google.com/store/apps/details?id=com.imaginationroom.spacman",
        ios: "https://apps.apple.com/ca/app/spacman/id1548545256",
      },
      thumbImage: "images/projects/spacman.webp",
      sliderImages: [
        "images/projects/spacman.webp",
        "images/projects/spacman-2.webp",
        "images/projects/spacman-3.webp",
        "images/projects/spacman-4.webp",
        "images/projects/spacman-5.webp",
      ],
      categories: [filters.FLUTTER_MOBILE],
    },
    {
      title: "Pool",
      projectInfo: `Private media sharing for Photographers and Content Creators.

      Capture, compile and share your content in original quality with your team, clients, family and friends. Allow everyone to vote or download the content that they would like to keep.
      `,
      technologies: "Flutter, Node.js, Firebase RealtimeDB, Firebase Cloud Functions, Google Play Subscribtion, AppStore Subscription",
      urls: {
        android: "https://play.google.com/store/apps/details?id=com.imaginationroom.pool",
        ios: "https://apps.apple.com/ca/app/pool-private-photo-sharing/id1453615144",
        web: "https://www.sharewithpool.com"
      },
      thumbImage: "images/projects/pool.webp",
      sliderImages: [
        "images/projects/pool.webp",
        "images/projects/pool-2.webp",
        "images/projects/pool-3.webp",
        "images/projects/pool-4.webp",
        "images/projects/pool-5.webp",
      ],
      categories: [filters.FLUTTER_MOBILE],
    },
    {
      title: "CoinMan",
      projectInfo: `Keep track of all of your favourite coins and receive customized news and alerts based on the coins you follow. View news and live chatter from Twitter and Reddit regarding your watchlist.

      Follow as many top coins as you would like!`,
      technologies: "Flutter, Node.js, MongoDB, Google Play Subscribtion, AppStore Subscription",
      urls: {
        android: "https://play.google.com/store/apps/details?id=com.imaginationroom.coinman",
        ios: "https://apps.apple.com/ca/app/coinman-all-things-crypto/id1568750720",
      },
      thumbImage: "images/projects/coinman-3.webp",
      sliderImages: [
        "images/projects/coinman.webp",
        "images/projects/coinman-2.webp",
        "images/projects/coinman-3.webp",
      ],
      categories: [filters.FLUTTER_MOBILE],
    },
    {
      title: "NFTMan",
      projectInfo: `Follow your favourite NFT collections, and get instant news updates from various channels like Twitter, Telegram, and Reddit, all in one place.
      See which collections are the top ranked among different marketplaces, and click into any collection to reveal more details on floor price, market cap, volume etc.`,
      technologies: "Flutter, Node.js, MongoDB, Google Play Subscribtion, AppStore Subscription",
      urls: {
        android: "https://play.google.com/store/apps/details?id=com.imaginationroom.nftman",
        ios: "https://apps.apple.com/ca/app/nftman/id1609672113",
      },
      thumbImage: "images/projects/nftman.webp",
      sliderImages: [
        "images/projects/nftman.webp",
        "images/projects/nftman-2.webp",
        "images/projects/nftman-3.webp",
      ],
      categories: [filters.FLUTTER_MOBILE],
    },

    {
      title: "Carhop",
      projectInfo: `How many seconds can you drive for?
      •Swipe left or right to dodge cars
      •Swipe up to hop over cars`,
      technologies: "Unity, Google AdMob",
      urls: {
        android: "https://play.google.com/store/apps/details?id=com.imaginationroom.carhop",
        ios: "https://apps.apple.com/ca/app/car-hop/id1497613359",
      },
      thumbImage: "images/projects/carhop.webp",
      sliderImages: [
        "images/projects/carhop.webp",
        "images/projects/carhop-2.webp",
      ],
      categories: [filters.UNITY],
    },
    {
      title: "Jump Shots+",
      projectInfo: `How many shots can you score?
      •Tap and hold the ball to aim
      •Swipe up and release to shoot`,
      technologies: "Unity, Google AdMob",
      urls: {
        android: "https://play.google.com/store/apps/details?id=com.imaginationroom.jumpshots",
        ios: "https://apps.apple.com/ca/app/jump-shots/id1496648418",
      },
      thumbImage: "images/projects/jumpshots.webp",
      sliderImages: [
        "images/projects/jumpshots.webp",
        "images/projects/jumpshots-2.webp",
      ],
      categories: [filters.UNITY],
    },
    {
      title: "Matched - Card Game",
      projectInfo: `A card game you can play with friends & family! Be the first to spot the matches to steal your friends cards.

      Play single player or multiplayer and have your friends join from around the world.`,
      technologies: "Flutter, Node.js, Google AdMob",
      urls: {
        android: "https://play.google.com/store/apps/details?id=com.imaginationroom.matched",
        ios: "https://apps.apple.com/ca/app/matched-card-game/id1512013365",
      },
      thumbImage: "images/projects/matched-2.webp",
      sliderImages: [
        "images/projects/matched.webp",
        "images/projects/matched-2.webp",
        "images/projects/matched-3.webp",
        "images/projects/matched-4.webp",
      ],
      categories: [filters.FLUTTER_MOBILE],
    },

    {
      title: "Find Me. - Puzzle Game",
      projectInfo: `Can you spot the odd emoji?

      Race against the clock while trying to find the single emoji in the puzzle that is different from all of the rest. The quicker you find it, the more stars you earn.
      
      Play single player or multiplayer against your friends. Share the game code so all of your friends can join a 10 round series.
      
      With each level having a new challenge and surprise, you have the ability to collect gems that can be used to help you throughout each level as well as in multiplayer mode.`,
      technologies: "Flutter, Node.js, Google AdMob, In-app Purchase",
      urls: {
        android: "https://play.google.com/store/apps/details?id=com.imaginationroom.findme",
        ios: "https://apps.apple.com/ca/app/find-me-puzzle-game/id1503076612",
      },
      thumbImage: "images/projects/findme.webp",
      sliderImages: [
        "images/projects/findme.webp",
        "images/projects/findme-2.webp",
        "images/projects/findme-3.webp",
      ],
      categories: [filters.FLUTTER_MOBILE],
    },

    {
      title: "Breathe Easy",
      projectInfo: `Breathe Easy implements the well known 4-7-8 breathing technique and cycles through each breath while increasing your mood and your emoji's mood.

      Once you have completed your deep breathing cycle, Breathe Easy will send you a notification once your emoji has degraded to a certain mood. This ensures that you remember to pause and take a deep breath throughout your day.`,
      technologies: "Flutter, Node.js, Google AdMob",
      urls: {
        android: "https://play.google.com/store/apps/details?id=com.imaginationroom.breathe",
        ios: "https://apps.apple.com/ca/app/breathe-easy-478-breathing/id1507256792",
      },
      thumbImage: "images/projects/breathe.webp",
      sliderImages: [
        "images/projects/breathe.webp",
      ],
      categories: [filters.FLUTTER_MOBILE],
    },

    {
      title: "Xtrava Health Cloud",
      projectInfo: `Firestore InsightHub is a cutting-edge web portal that enhances your Firebase Firestore experience. It seamlessly combines advanced visualization, document editing, webhook integration, user management, and KPI reporting, empowering developers, administrators, and stakeholders to fully leverage Firestore databases with ease.`,
      technologies: "Flutter, Node.js, Firebase Firestore, Cloud Functions",
      urls: {
        web: "https://xtrava-cloud-marketing.web.app"
      },
      thumbImage: "images/projects/xtrava.png",
      sliderImages: [
        "images/projects/xtrava.png",
        "images/projects/xtrava-2.png",
        "images/projects/xtrava-3.png",
        "images/projects/xtrava-4.png",
        "images/projects/xtrava-5.png",
        "images/projects/xtrava-6.png",
      ],
      categories: [filters.FLUTTER_WEB],
    },
    {
      title: "VanRec",
      projectInfo: `Search for activities across Vancouver's recreactional centres.`,
      technologies: "Flutter, Supabase",
      urls: {
        web: "https://vanrec.kasem.dev/"
      },
      thumbImage: "images/projects/vanrec.png",
      sliderImages: [
        "images/projects/vanrec.png",
        "images/projects/vanrec-2.png",
      ],
      categories: [filters.FLUTTER_WEB],
    },
    {
      title: "Bread Formula",
      projectInfo: `A calculator to help you measure your sourdough ingredients.`,
      technologies: "Flutter",
      urls: {
        web: "https://bread.kasem.dev/"
      },
      thumbImage: "images/projects/bread.png",
      sliderImages: [
        "images/projects/bread.png",
      ],
      categories: [filters.FLUTTER_WEB],
    },

    {
      title: "Zero Messenger",
      projectInfo: `Zero Messenger was designed with two things in mind; privacy and security. Leaving full control in the users' hands, we never collect any user information or user data.

      Zero uses the latest multi-ratchet encryption technologies to protect your data from inception, through transit, to delivery, but never retains any of your messages or contacts.`,
      technologies: "Java, Kotlin, Native Android",
      urls: {
        android: "https://play.google.com/store/apps/details?id=com.imaginationroom.zeromessenger",
      },
      thumbImage: "images/projects/zero.webp",
      sliderImages: [
        "images/projects/zero.webp",
        "images/projects/zero-2.webp",
        "images/projects/zero-3.webp",
      ],
      categories: [filters.ANDROID],
    },

    {
      title: "Butterfly",
      projectInfo: `Whether sleeping in or outside the crib on their back, side or stomach, Butterfly tracks your baby’s breathing, letting you know if you need to check on her.

      Whether you are safe co-sleeping or sleep training, Butterfly observes your baby’s activities, sleep and environment to provide you insights on how to help her get better sleep.
      
      Whether she is swaddled at home or all dressed up outside, Butterfly senses your baby’s diaper over her clothing, sending you a notification when it’s time for a change.`,
      technologies: "Java, Kotlin, Native Android, C++, BLE(GATT)",
      urls: {
        android: "https://play.google.com/store/apps/details?id=co.xtrava.butterfly&hl=en-US&ah=ktcrDun7pyUxnE-bQBslrfuHeYU",
      },
      thumbImage: "images/projects/butterfly.webp",
      sliderImages: [
        "images/projects/butterfly.webp",
        "images/projects/butterfly-2.webp",
      ],
      categories: [filters.ANDROID],
    },

    {
      title: "DMAT",
      projectInfo: `Short for The Abu Dhabi Department of Municipal Affairs and Transport. Managers enter and manage their divisions K.P.I’s records by entering new achieved values against the predefined K.P.I’s by system administrations. Managers also are able to search the library for different records, export the search results to Excel or PDF, and also to have a notification system to remind them to enter the new S.T.L records values.`,
      technologies: "Java, Native Android",
      thumbImage: "images/projects/dmat-2.jpg",
      sliderImages: [
        "images/projects/dmat.jpg",
        "images/projects/dmat-2.jpg",
        "images/projects/dmat-3.jpg",
      ],
      categories: [filters.ANDROID],
    },

    {
      title: "Meeting Minutes For Android",
      projectInfo: `Visual seating, Meeting scheduling, Meeting agenda,Meeting documents, Meeting notes, Meeting attendees, Meeting recording, Meeting camera, Meeting action points, Meetings archiving, Meeting minutes and much more`,
      technologies: "Java, Native Android",
      thumbImage: "images/projects/meeting.jpeg",
      sliderImages: [
        "images/projects/meeting.jpeg",
      ],
      categories: [filters.ANDROID],
    },

  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey].replace(' ', '') ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey].replace(' ', ''))}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.map((e) => e.replace(' ', '')).join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
