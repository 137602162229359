import React from "react";
const resumeFile = "https://docs.google.com/document/d/1e9iiaVnSrh3cTjW1qjO7ghX5YCg3UqIh/export?format=pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2008 - 20013",
      title: "Bachelor’s degree in Electrical Engineering and Computers",
      place: "Higher Technological Institute, Egypt",
      desc: "I graduated with an excellent cumulative GPA of 3.68 and received three certificates of scientific excellence in 2010, 2011, and 2012.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "Nov 2023 - Present",
      title: "Senior Software Engineer",
      place: "Aira",
      desc: [
        `Working with the Flutter team.`,
      ],
    },
    {
      yearRange: "May 2022 - Nov 2023",
      title: "Lead Software Engineer",
      place: "Xtrava Health",
      desc: [
        `Helped the company reduce operation costs significantly by moving away from a third party cloud solution to a custom built one. This transition also allowed the company to have full control of the features they want to have included.`,
        `Designed and built a responsive web admin portal from scratch using Flutter.`,
        `Built an Android and iOS app that connects to a medical device. Targeting visually impaired users to effectively test for Covid-19.`,
        `Built backend services with Node.js.`,
        `Explored AI options using OpenAI and VertexAI. Which consisted of working with data embedding, vector database and langchain.`,
      ],
    },
    {
      yearRange: "Sep 2018 - May 2022",
      title: "Full Stack Software Developer",
      place: "Imagination Room",
      desc: [
        `Utilized Flutter extensively to build UI and business logic for various Android and iOS apps, including media sharing, SPAC merger alerts, cryptocurrency news, mental health relaxation, and multi-user games.`,
        `Implemented backend services using Node.js.`,
        `Experimented with Unity for game development, resulting in the creation of two mobile games.`,
        `Monetized apps using Google AdSense and integrated subscription methods for Android and iOS platforms.`,
      ],
    },
    {
      yearRange: "Oct 2017 - Sep 2018",
      title: "Android Development Team Lead",
      place: "Xtrava",
      desc: "Developed and maintained an Android library SDK and worked on a Bluetooth Low Energy enabled application for data streaming and collection from BT devices, providing dashboard and control screens.",
    },
    {
      yearRange: "Feb 2013 - Oct 2017",
      title: "Mobile Development Team Lead",
      place: "Youxel Technologies",
      desc: [
        `Transitioned from junior to senior programming skills, progressing to team leadership.`,
        `Worked on a range of projects, spanning B2B and B2C domains.`,
        `Excelled in collaborative teamwork and effective project planning.`,
        `Developed android apps featuring multimedia, animation, and cloud integration.`,
        `Created Web Services using ASP.NET that served mobile apps, leveraging OAuth 2.0 and JWT for enhanced security.`,
        `Prioritized exceptional user experiences and UI design in app development.`,
        `Ensured security and privacy considerations were integral to the development process.`,
        `Successfully published apps on Google Play.`,
      ],
    },
  ];

  const skills = [
    {
      name: "Dart/Flutter",
      percent: 100,
    },
    {
      name: "Java/Kotlin/Android",
      percent: 100,
    },
    {
      name: "JavaScript/TypeScript/Node.js",
      percent: 100,
    },
    {
      name: "SQL",
      percent: 100,
    },
    {
      name: "NoSQL/MongoDB/Firestore/RealtimeDB",
      percent: 100,
    },
    {
      name: "C#/.NET",
      percent: 90,
    },
    {
      name: "Python",
      percent: 60,
    },
    {
      name: "React JS",
      percent: 30,
    },
    {
      name: "C/C++/Embedded Systems",
      percent: 80,
    },
    {
      name: "Swift/Objective-C/iOS",
      percent: 20,
    },
    {
      name: "Unity",
      percent: 30,
    },
    {
      name: "Go",
      percent: 20,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  {Array.isArray(value.desc) ? (
                    <ul className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                      {value.desc.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ul>
                  ) : (
                    <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                      {value.desc}
                    </p>
                  )}
                </div>
              ))}
          </div>
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>

        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download Resumé
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
