import React from "react";
const resumeFile = "https://docs.google.com/document/d/1e9iiaVnSrh3cTjW1qjO7ghX5YCg3UqIh/export?format=pdf";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Know Me More
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Kasem,</span> a Senior Software Engineer
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
              With over a decade of comprehensive experience in software development,
              design, and integration. Proven track record of delivering applications
              to the PlayStore, AppStore and Web. Proficient across all stages of development,
              with expertise spanning backend and frontend domains.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              What sets me apart is my ability to rapidly learn and adapt.
              In the ever-evolving tech landscape, I am committed to staying
              ahead by quickly grasping new technologies. This adaptability
              has been key to my success in delivering results.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Kasem Mohamed
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:kasem.jaffer@gmail.com">kasem.jaffer@gmail.com</a>
                </li>
                <li>
                  <span className="fw-600 me-2">Age:</span>{new Date().getFullYear() - 1989}
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Vancouver,
                  British Columbia
                </li>
              </ul>
              <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                Download Resumé
              </a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>{new Date().getFullYear() - 2013}</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experiance
                </p>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>20+</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>100K+</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  App Downloads
                </p>
              </div>
            </div>

          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
