import React from "react";

import Slider from "react-slick";

const Testimonials = ({ classicHeader, darkTheme }) => {
  const reviews = [
    {
      name: "Brandan Bath",
      position: "Founder of Imagination Room",
      // src: "images/testimonial/client-sm-3.jpg",
      desc: "As the founder of Imagination Room, Kasem directly reported to me daily on all of our projects. Working with Kasem was always pleasant as he is a self-starter and is motivated to finding solutions to even the most complex problems. We worked together on over a dozen mobile apps from chat messengers to a photo sharing app, and numerous mobile games. Kasem was always willing to help out other team members and work cohesively with everyone on the team from developers to the design team.",
      // rating: 5,
    },
    {
      name: "Hussein Gaber",
      position: "Head of design at Tremoloo",
      // src: "images/testimonial/client-sm-1.jpg",
      desc: "Kasem is someone you really enjoy working with, You can travel the world with his own thoughts. He is smart, dedicated, updated with technology trends and I do recommend him for any dev. team.",
      // rating: 5,
    },
    {
      name: "Ahmed R. El-Tarabolsy",
      position: "Junior colleague that I used to mentor",
      // src: "images/testimonial/client-sm-4.jpg",
      desc: "I have had the obligation to work with Kasem on different levels and he was nothing but supportive and dedicated with so much sense of reason. He works under pressure and also provides support to his juniors as I was one of them. A very reliable developer who manages to multi task in lots of different fields. ",
      // rating: 5,
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Testimonial
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Client Speak
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    {/* <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                    /> */}
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    “{value.desc}”
                  </p>
                  {/* <span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span> */}
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
